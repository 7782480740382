import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchBox = _resolveComponent("SearchBox")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_main = _resolveComponent("el-main")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_el_main, null, {
    default: _withCtx(() => [
      _createVNode(_component_SearchBox, {
        class: "search",
        placeholder: "請輸入會員編號",
        clearable: "",
        clickSearch: _ctx.searchMemberNo,
        inputWidth: "220px"
      }, null, 8, ["clickSearch"]),
      _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        data: _ctx.data?.data,
        border: "",
        fit: "",
        "highlight-current-row": "",
        style: {"width":"100%"},
        "empty-text": "暫無資料"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "時間",
            prop: "createdAt"
          }, {
            default: _withCtx(({row: {createdAt}}) => [
              _createTextVNode(_toDisplayString(_ctx.formatLocalTime(createdAt)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "番號",
            prop: "number"
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "影片名稱",
            prop: "name"
          })
        ]),
        _: 1
      }, 8, ["data"])), [
        [_directive_loading, _ctx.isLoading || _ctx.isFetching]
      ])
    ]),
    _: 1
  }))
}