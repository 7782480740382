
import { defineComponent, ref } from 'vue';
import SearchBox from '@/components/keyword-searching/Index.vue';
import { useWatchHistories } from '@/composables/api';
import { formatLocalTime } from '@/utils/format-time';

export default defineComponent({
  components: {
    SearchBox
  },
  setup() {
    const memberNo = ref('');
    const { data, isLoading, isFetching } = useWatchHistories({ memberNo });

    const searchMemberNo = (_memberNo: string) => {
      memberNo.value = _memberNo;
    };

    return {
      data,
      isLoading,
      isFetching,
      searchMemberNo,
      formatLocalTime
    };
  }
});
